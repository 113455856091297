import { template as template_6aed062758c64d30a008aa3a2439bd7c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_6aed062758c64d30a008aa3a2439bd7c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
