import { template as template_7703de2569c14c55bb67158ec4b18b7d } from "@ember/template-compiler";
const FKControlMenuContainer = template_7703de2569c14c55bb67158ec4b18b7d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
