import { template as template_f768a8ef6c8e41b3bfd7e02e7b4a2209 } from "@ember/template-compiler";
const FKLabel = template_f768a8ef6c8e41b3bfd7e02e7b4a2209(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
