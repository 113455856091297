import { template as template_3f937e0e6eda4cc2b428eaa1f518ce5d } from "@ember/template-compiler";
const WelcomeHeader = template_3f937e0e6eda4cc2b428eaa1f518ce5d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
